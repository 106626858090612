import {
  FETCH_ALL_PUBLISHERS_SUCCESS,
  FETCH_PUBLISHERS_BY_ID_SUCCESS,
  FETCH_BOOKS_BY_PUBLISHER_ID_SUCCESS,
  EMPTY_PUBLISHERS_BOOKS,
  CREATE_PUBLISHER_SUCCESS,
  EDIT_PUBLISHER_SUCCESS,
  RESTORE_CREATE_PUBLISHER_INITIAL,
  RESTORE_EDIT_PUBLISHER_INITIAL,
  SEARCH_PUBLISHERS_BOOK_SUCCESS,
  DELETE_PUBLISHER_SUCCESS,
  RESTORE_DELETE_PUBLISHER_INITIAL,
  FETCH_PUBLISHER_BOOKS_SUCCESS,
  FETCH_PUBLISHER_BOOKS_PERFORMANCE_SUCCESS,
} from "../ActionTypes";

const INIT_STATE = {
  allPublisher: [],
  publisherById: {},
  bookByPublisherId: [],

  addPublisherSuccess: false,
  addPublisherFailure: false,

  editPublisherSuccess: false,
  editPublisherFailure: false,

  deletePublisherSuccess: false,
  deletePublisherFailure: false,

  searchPubBook: {},

  publisherBooks: [],
  publisherBooksPerformance: {},
};

const publisherReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_PUBLISHERS_SUCCESS: {
      return {
        ...state,
        allPublisher: action.payload,
      };
    }
    case FETCH_PUBLISHERS_BY_ID_SUCCESS: {
      return {
        ...state,
        publisherById: action.payload,
      };
    }
    case FETCH_BOOKS_BY_PUBLISHER_ID_SUCCESS: {
      return {
        ...state,
        bookByPublisherId: action.payload,
      };
    }

    case EMPTY_PUBLISHERS_BOOKS: {
      return {
        ...state,
        bookByPublisherId: [],
      };
    }

    case SEARCH_PUBLISHERS_BOOK_SUCCESS: {
      return {
        ...state,
        searchPubBook: action.payload,
      };
    }

    case CREATE_PUBLISHER_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          addPublisherSuccess: true,
          addPublisherFailure: false,
        };
      } else {
        return {
          ...state,
          addPublisherSuccess: false,
          addPublisherFailure: true,
        };
      }
    }

    case RESTORE_CREATE_PUBLISHER_INITIAL: {
      return {
        ...state,
        addPublisherSuccess: false,
        addPublisherFailure: false,
      };
    }

    case EDIT_PUBLISHER_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          editPublisherSuccess: true,
          editPublisherFailure: false,
        };
      } else {
        return {
          ...state,
          editPublisherSuccess: false,
          editPublisherFailure: true,
        };
      }
    }

    case RESTORE_EDIT_PUBLISHER_INITIAL: {
      return {
        ...state,
        editPublisherSuccess: false,
        editPublisherFailure: false,
      };
    }

    case DELETE_PUBLISHER_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          deletePublisherSuccess: true,
          deletePublisherFailure: false,
        };
      } else {
        return {
          ...state,
          deletePublisherSuccess: false,
          deletePublisherFailure: true,
        };
      }
    }

    case RESTORE_DELETE_PUBLISHER_INITIAL: {
      return {
        ...state,
        deletePublisherSuccess: false,
        deletePublisherFailure: false,
      };
    }

    case FETCH_PUBLISHER_BOOKS_SUCCESS: {
      return {
        ...state,
        publisherBooks: action.payload,
      };
    }

    case FETCH_PUBLISHER_BOOKS_PERFORMANCE_SUCCESS: {
      return {
        ...state,
        publisherBooksPerformance: action.payload,
      };
    }

    default:
      return state;
  }
};

export default publisherReducer;
