import {
  FETCH_ALL_AUTHORS,
  FETCH_ALL_AUTHORS_SUCCESS,
  ADD_AUTHORS,
  ADD_AUTHORS_SUCCESS,
  RESTORE_ADD_AUTHORS_INITIAL,
  EDIT_AUTHORS,
  EDIT_AUTHORS_SUCCESS,
  RESTORE_EDIT_AUTHORS_INITIAL,
  FETCH_BEST_AUTHORS_CSV,
  FETCH_BEST_AUTHORS_CSV_SUCCESS,
  DELETE_AUTHORS,
  DELETE_AUTHORS_SUCCESS,
  RESTORE_DELETE_AUTHORS_INITIAL,
  FETCH_AUTHORS_BY_ID,
  FETCH_AUTHORS_BY_ID_SUCCESS,
  FETCH_ALL_AUTHORS_NO_PAGINATION,
  FETCH_ALL_AUTHORS_NO_PAGINATION_SUCCESS,
  SEARCH_AUTHOR,
  SEARCH_AUTHOR_SUCCESS,
} from "../ActionTypes";

export const fetchAuthors = (id) => ({
  type: FETCH_ALL_AUTHORS,
  payload: id,
});

export const fetchAuthorsSuccess = (payload) => ({
  type: FETCH_ALL_AUTHORS_SUCCESS,
  payload,
});

export const searchAuthor = ({ search }) => ({
  type: SEARCH_AUTHOR,
  payload: {
    search,
  },
});

export const searchAuthorSuccess = (payload) => ({
  type: SEARCH_AUTHOR_SUCCESS,
  payload,
});

export const fetchAuthorsNoPagination = () => ({
  type: FETCH_ALL_AUTHORS_NO_PAGINATION,
});

export const fetchAuthorsNoPaginationSuccess = (payload) => ({
  type: FETCH_ALL_AUTHORS_NO_PAGINATION_SUCCESS,
  payload,
});

export const fetchAuthorsById = (id) => ({
  type: FETCH_AUTHORS_BY_ID,
  payload: id,
});

export const fetchAuthorsByIdSuccess = (payload) => ({
  type: FETCH_AUTHORS_BY_ID_SUCCESS,
  payload,
});

export const fetchBestAuthors = () => ({
  type: FETCH_BEST_AUTHORS_CSV,
});

export const fetchBestAuthorsSuccess = (payload) => ({
  type: FETCH_BEST_AUTHORS_CSV_SUCCESS,
  payload,
});

export const addAuthors = (payload) => ({
  type: ADD_AUTHORS,
  payload,
});

export const addAuthorsSuccess = (payload) => ({
  type: ADD_AUTHORS_SUCCESS,
  payload,
});

export const restoreAddAuthorsInitial = () => ({
  type: RESTORE_ADD_AUTHORS_INITIAL,
});

export const editAuthors = ({ formData, authorId }) => ({
  type: EDIT_AUTHORS,
  payload: {
    formData,
    authorId,
  },
});

export const editAuthorsSuccess = (payload) => ({
  type: EDIT_AUTHORS_SUCCESS,
  payload,
});

export const restoreEditAuthorsInitial = () => ({
  type: RESTORE_EDIT_AUTHORS_INITIAL,
});

export const deleteAuthors = (id) => ({
  type: DELETE_AUTHORS,
  payload: id,
});

export const deleteAuthorsSuccess = (payload) => ({
  type: DELETE_AUTHORS_SUCCESS,
  payload,
});

export const restoreDeleteAuthorsInitial = () => ({
  type: RESTORE_DELETE_AUTHORS_INITIAL,
});
