import {
  FETCH_ALL_GENRE,
  FETCH_ALL_GENRE_SUCCESS,
  FETCH_SUBGENRE,
  FETCH_SUBGENRE_SUCCESS,
  EMPTY_SUBGENRE,
  ADD_GENRE,
  ADD_GENRE_SUCCESS,
  RESTORE_ADD_GENRE_INITIAL,
  EDIT_GENRE,
  EDIT_GENRE_SUCCESS,
  RESTORE_EDIT_GENRE_INITIAL,
  DELETE_GENRE,
  DELETE_GENRE_SUCCESS,
  RESTORE_DELETE_GENRE_INITIAL,
  ADD_SUBGENRE,
  ADD_SUBGENRE_SUCCESS,
  RESTORE_ADD_SUBGENRE_INITIAL,
  EDIT_SUBGENRE,
  EDIT_SUBGENRE_SUCCESS,
  RESTORE_EDIT_SUBGENRE_INITIAL,
  DELETE_SUBGENRE,
  DELETE_SUBGENRE_SUCCESS,
  RESTORE_DELETE_SUBGENRE_INITIAL,
  FETCH_GENRE_BY_ID,
  FETCH_GENRE_BY_ID_SUCCESS,
  FETCH_SUBGENRE_BY_ID,
  FETCH_SUBGENRE_BY_ID_SUCCESS,
} from "../ActionTypes";

export const fetchAllGenre = () => ({
  type: FETCH_ALL_GENRE,
});

export const fetchAllGenreSuccess = (payload) => ({
  type: FETCH_ALL_GENRE_SUCCESS,
  payload,
});

export const fetchSubGenre = (id) => ({
  type: FETCH_SUBGENRE,
  payload: id,
});

export const fetchSubGenreSuccess = (payload) => ({
  type: FETCH_SUBGENRE_SUCCESS,
  payload,
});

export const emptySubGenre = () => ({
  type: EMPTY_SUBGENRE,
});

export const addGenre = ({ title }) => ({
  type: ADD_GENRE,
  payload: {
    title,
  },
});

export const addGenreSuccess = (payload) => ({
  type: ADD_GENRE_SUCCESS,
  payload,
});

export const restoreAddGenreInitial = () => ({
  type: RESTORE_ADD_GENRE_INITIAL,
});

export const editGenre = ({ title, id }) => ({
  type: EDIT_GENRE,
  payload: {
    title,
    id,
  },
});

export const editGenreSuccess = (payload) => ({
  type: EDIT_GENRE_SUCCESS,
  payload,
});

export const restoreEditGenreInitial = () => ({
  type: RESTORE_EDIT_GENRE_INITIAL,
});

export const deleteGenre = (id) => ({
  type: DELETE_GENRE,
  payload: id,
});

export const deleteGenreSuccess = (payload) => ({
  type: DELETE_GENRE_SUCCESS,
  payload,
});

export const restoreDeleteGenreInitial = () => ({
  type: RESTORE_DELETE_GENRE_INITIAL,
});

export const addSubGenre = ({ title, id }) => ({
  type: ADD_SUBGENRE,
  payload: {
    title,
    id,
  },
});

export const addSubGenreSuccess = (payload) => ({
  type: ADD_SUBGENRE_SUCCESS,
  payload,
});

export const restoreAddSubGenreInitial = () => ({
  type: RESTORE_ADD_SUBGENRE_INITIAL,
});

export const editSubGenre = ({ title, id }) => ({
  type: EDIT_SUBGENRE,
  payload: {
    title,
    id,
  },
});

export const editSubGenreSuccess = (payload) => ({
  type: EDIT_SUBGENRE_SUCCESS,
  payload,
});

export const restoreEditSubGenreInitial = () => ({
  type: RESTORE_EDIT_SUBGENRE_INITIAL,
});

export const deleteSubGenre = (id) => ({
  type: DELETE_SUBGENRE,
  payload: id,
});

export const deleteSubGenreSuccess = (payload) => ({
  type: DELETE_SUBGENRE_SUCCESS,
  payload,
});

export const restoreDeleteSubGenreInitial = () => ({
  type: RESTORE_DELETE_SUBGENRE_INITIAL,
});

export const fetchGenreById = (id) => ({
  type: FETCH_GENRE_BY_ID,
  payload: id,
});

export const fetchGenreByIdSuccess = (payload) => ({
  type: FETCH_GENRE_BY_ID_SUCCESS,
  payload,
});

export const fetchSubGenreById = (id) => ({
  type: FETCH_SUBGENRE_BY_ID,
  payload: id,
});

export const fetchSubGenreByIdSuccess = (payload) => ({
  type: FETCH_SUBGENRE_BY_ID_SUCCESS,
  payload,
});
