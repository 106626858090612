import {
  FETCH_REFERRED_USER,
  FETCH_REFERRED_USER_SUCCESS,
  FETCH_REFERRALS_BY_USER,
  FETCH_REFERRALS_BY_USER_SUCCESS,
} from "../ActionTypes";

export const fetchReferredUsers = (id) => ({
  type: FETCH_REFERRED_USER,
  payload: id,
});

export const fetchReferredUsersSuccess = (payload) => ({
  type: FETCH_REFERRED_USER_SUCCESS,
  payload,
});

export const fetchReferralsByUsers = () => ({
  type: FETCH_REFERRALS_BY_USER,
});

export const fetchReferralsByUsersSuccess = (payload) => ({
  type: FETCH_REFERRALS_BY_USER_SUCCESS,
  payload,
});
