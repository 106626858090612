import { SEND_NOTIFICATION_SUCCESS, RESTORE_SEND_NOTIFICATION_INITIAL } from "../ActionTypes";

const INIT_STATE = {
  sendNotificationSuccess: false,
  sendNotificationFailure: false,
};

const notificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEND_NOTIFICATION_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          sendNotificationSuccess: true,
          sendNotificationFailure: false,
        };
      } else {
        return {
          ...state,
          sendNotificationSuccess: false,
          sendNotificationFailure: true,
        };
      }
    }

    case RESTORE_SEND_NOTIFICATION_INITIAL: {
      return {
        ...state,
        sendNotificationSuccess: false,
        sendNotificationFailure: false,
      };
    }

    default:
      return state;
  }
};

export default notificationReducer;
