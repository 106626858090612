import {
  FETCH_ALL_USERS_SUCCESS,
  EMPTY_USERS,
  CANCEL_SUBSCRIPTION_SUCCESS,
  RESTORE_CANCEL_SUBSCRIPTION_INITIAL,
  TRIAL_SUBSCRIPTION_SUCCESS,
  RESTORE_TRIAL_SUBSCRIPTION_INITIAL,
  LONE_RANGER_MONTHLY_SUBSCRIPTION_SUCCESS,
  RESTORE_LONE_RANGER_MONTHLY_SUBSCRIPTION_INITIAL,
  LONE_RANGER_QUARTERLY_SUBSCRIPTION_SUCCESS,
  RESTORE_LONE_RANGER_QUARTERLY_SUBSCRIPTION_INITIAL,
  LONE_RANGER_BIANNUAL_SUBSCRIPTION_SUCCESS,
  RESTORE_LONE_RANGER_BIANNUAL_SUBSCRIPTION_INITIAL,
  LONE_RANGER_YEARLY_SUBSCRIPTION_SUCCESS,
  RESTORE_LONE_RANGER_YEARLY_BIANNUAL_SUBSCRIPTION_INITIAL,
  EXTEND_USER_SUBSCRIPTION_SUCCESS,
  RESTORE_EXTEND_USER_SUBSCRIPTION_INITIAL,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  RESTORE_REACTIVATE_SUBSCRIPTION_INITIAL,
  SEARCH_USERS_SUCCESS,
  DELETE_USERS_SUCCESS,
  RESTORE_DELETE_USER_INITIAL,
  UPDATE_USER_SUCCESS,
  RESTORE_UPDATE_USER_INITIAL,
  FETCH_USER_BY_ID_SUCCESS,
} from "../ActionTypes";

const INIT_STATE = {
  allUsers: [],

  cancelSubSuccess: false,
  cancelSubFailure: false,

  activateTrialSubSuccess: false,
  activateTrialSubFailure: false,

  activateLoneRangerMonthlySubSuccess: false,
  activateLoneRangerMonthlySubFailure: false,

  activateLoneRangerQuarterlySubSuccess: false,
  activateLoneRangerQuarterlySubFailure: false,

  activateLoneRangerBiannualSubSuccess: false,
  activateLoneRangerBiannualSubFailure: false,

  activateLoneRangerYearlySubSuccess: false,
  activateLoneRangerYearlySubFailure: false,

  extendUserSubscriptionSuccess: false,
  extendUserSubscriptionFailure: false,

  reactivateSubscriptionSuccess: false,
  reactivateSubscriptionFailure: false,

  searchedUsers: [],
  deleteUserSuccess: false,
  deleteUserFailure: false,

  updateUserSuccess: false,
  updateUserFailure: false,

  userById: {},
};

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_USERS_SUCCESS: {
      return {
        ...state,
        allUsers: action.payload,
      };
    }

    case EMPTY_USERS: {
      return {
        ...state,
        allUsers: [],
      };
    }

    case SEARCH_USERS_SUCCESS: {
      return {
        ...state,
        searchedUsers: action.payload,
      };
    }

    case CANCEL_SUBSCRIPTION_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          cancelSubSuccess: true,
          cancelSubFailure: false,
        };
      } else {
        return {
          ...state,
          cancelSubSuccess: false,
          cancelSubFailure: true,
        };
      }
    }

    case RESTORE_CANCEL_SUBSCRIPTION_INITIAL: {
      return {
        ...state,
        cancelSubSuccess: false,
        cancelSubFailure: false,
      };
    }

    case REACTIVATE_SUBSCRIPTION_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          reactivateSubscriptionSuccess: true,
          reactivateSubscriptionFailure: false,
        };
      } else {
        return {
          ...state,
          reactivateSubscriptionSuccess: false,
          reactivateSubscriptionFailure: true,
        };
      }
    }

    case RESTORE_REACTIVATE_SUBSCRIPTION_INITIAL: {
      return {
        ...state,
        reactivateSubscriptionSuccess: false,
        reactivateSubscriptionFailure: false,
      };
    }

    case TRIAL_SUBSCRIPTION_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          activateTrialSubSuccess: true,
          activateTrialSubFailure: false,
        };
      } else {
        return {
          ...state,
          activateTrialSubSuccess: false,
          activateTrialSubFailure: true,
        };
      }
    }

    case RESTORE_TRIAL_SUBSCRIPTION_INITIAL: {
      return {
        ...state,
        activateTrialSubFailure: false,
        activateTrialSubSuccess: false,
      };
    }

    case LONE_RANGER_MONTHLY_SUBSCRIPTION_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          activateLoneRangerMonthlySubSuccess: true,
          activateLoneRangerMonthlySubFailure: false,
        };
      } else {
        return {
          ...state,
          activateLoneRangerMonthlySubSuccess: false,
          activateLoneRangerMonthlySubFailure: true,
        };
      }
    }

    case RESTORE_LONE_RANGER_MONTHLY_SUBSCRIPTION_INITIAL: {
      return {
        ...state,
        activateLoneRangerMonthlySubSuccess: false,
        activateLoneRangerMonthlySubFailure: false,
      };
    }

    case LONE_RANGER_QUARTERLY_SUBSCRIPTION_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          activateLoneRangerQuarterlySubSuccess: true,
          activateLoneRangerQuarterlySubFailure: false,
        };
      } else {
        return {
          ...state,
          activateLoneRangerQuarterlySubSuccess: false,
          activateLoneRangerQuarterlySubFailure: true,
        };
      }
    }

    case RESTORE_LONE_RANGER_QUARTERLY_SUBSCRIPTION_INITIAL: {
      return {
        ...state,
        activateLoneRangerQuarterlySubSuccess: false,
        activateLoneRangerQuarterlySubFailure: false,
      };
    }

    case LONE_RANGER_BIANNUAL_SUBSCRIPTION_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          activateLoneRangerBiannualSubSuccess: true,
          activateLoneRangerBiannualSubFailure: false,
        };
      } else {
        return {
          ...state,
          activateLoneRangerBiannualSubSuccess: false,
          activateLoneRangerBiannualSubFailure: true,
        };
      }
    }

    case RESTORE_LONE_RANGER_BIANNUAL_SUBSCRIPTION_INITIAL: {
      return {
        ...state,
        activateLoneRangerBiannualSubSuccess: false,
        activateLoneRangerBiannualSubFailure: false,
      };
    }

    case LONE_RANGER_YEARLY_SUBSCRIPTION_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          activateLoneRangerYearlySubSuccess: true,
          activateLoneRangerYearlySubFailure: false,
        };
      } else {
        return {
          ...state,
          activateLoneRangerYearlySubSuccess: false,
          activateLoneRangerYearlySubFailure: true,
        };
      }
    }

    case RESTORE_LONE_RANGER_YEARLY_BIANNUAL_SUBSCRIPTION_INITIAL: {
      return {
        ...state,
        activateLoneRangerYearlySubSuccess: false,
        activateLoneRangerYearlySubFailure: false,
      };
    }

    case EXTEND_USER_SUBSCRIPTION_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          extendUserSubscriptionSuccess: true,
          extendUserSubscriptionFailure: false,
        };
      } else {
        return {
          ...state,
          extendUserSubscriptionSuccess: false,
          extendUserSubscriptionFailure: true,
        };
      }
    }

    case RESTORE_EXTEND_USER_SUBSCRIPTION_INITIAL: {
      return {
        ...state,
        extendUserSubscriptionSuccess: false,
        extendUserSubscriptionFailure: false,
      };
    }

    case DELETE_USERS_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          deleteUserSuccess: true,
          deleteUserFailure: false,
        };
      } else {
        return {
          ...state,
          deleteUserSuccess: false,
          deleteUserFailure: true,
        };
      }
    }

    case RESTORE_DELETE_USER_INITIAL: {
      return {
        ...state,
        deleteUserSuccess: false,
        deleteUserFailure: false,
      };
    }

    case FETCH_USER_BY_ID_SUCCESS: {
      return {
        ...state,
        userById: action.payload,
      };
    }

    case UPDATE_USER_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          updateUserSuccess: true,
          updateUserFailure: false,
        };
      } else {
        return {
          ...state,
          updateUserSuccess: false,
          updateUserFailure: true,
        };
      }
    }

    case RESTORE_UPDATE_USER_INITIAL: {
      return {
        ...state,
        updateUserSuccess: false,
        updateUserFailure: false,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
