import { all, call, takeEvery, fork } from "redux-saga/effects";
import requestFunction from "../Utils/sagasGenericFunction";

import {
  FETCH_ALL_BOOKS,
  ADD_BOOKS,
  EDIT_BOOKS,
  DELETE_BOOKS,
  FETCH_BOOKS_BY_ID,
  SEARCH_BOOKS,
} from "../ActionTypes";

import { getBooks, addBooks, editBooks, deleteBook, getBooksById, searchBooks } from "../Api";

import {
  fetchBooksSuccess,
  addBooksSuccess,
  editBooksSuccess,
  deleteBooksSuccess,
  fetchBooksByIdSuccess,
  searchBooksSuccess,
} from "../Actions";

export const fetchBooksRequest = function* ({ payload }) {
  yield call(requestFunction, fetchBooksSuccess, getBooks, payload);
};

export const fetchAllBoooks = function* () {
  yield takeEvery(FETCH_ALL_BOOKS, fetchBooksRequest);
};

export const fetchBooksByIdRequest = function* ({ payload }) {
  yield call(requestFunction, fetchBooksByIdSuccess, getBooksById, payload);
};

export const fetchBooksByIdd = function* () {
  yield takeEvery(FETCH_BOOKS_BY_ID, fetchBooksByIdRequest);
};

export const addBookRequest = function* ({ payload }) {
  yield call(requestFunction, addBooksSuccess, addBooks, payload);
};

export const addBoooks = function* () {
  yield takeEvery(ADD_BOOKS, addBookRequest);
};

export const editBookRequest = function* ({ payload }) {
  yield call(requestFunction, editBooksSuccess, editBooks, payload);
};

export const editBoookss = function* () {
  yield takeEvery(EDIT_BOOKS, editBookRequest);
};

export const deleteBooksRequest = function* ({ payload }) {
  yield call(requestFunction, deleteBooksSuccess, deleteBook, payload);
};

export const deleteBoooks = function* () {
  yield takeEvery(DELETE_BOOKS, deleteBooksRequest);
};

export const searchBooksRequest = function* ({ payload }) {
  yield call(requestFunction, searchBooksSuccess, searchBooks, payload);
};

export const searchBkss = function* () {
  yield takeEvery(SEARCH_BOOKS, searchBooksRequest);
};

export default function* rootSaga() {
  yield all([
    fork(fetchAllBoooks),
    fork(fetchBooksByIdd),
    fork(addBoooks),
    fork(editBoookss),
    fork(deleteBoooks),
    fork(searchBkss),
  ]);
}
