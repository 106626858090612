import api from "../Service";
import { toastr } from "react-redux-toastr";

const baseController = "api/admin/";

export const postSendNotification = async (body) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.post(`${baseController}dashboard/notification/create`, body, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          toastr.success(`${response.data.message}`, "Success");
          return response.data.status;
        } else {
          toastr.error("Something went wrong", "Please try again");
          return response.data.status;
        }
      } else {
        toastr.error("An Error Occured", "Please try again");
      }
    } catch (ex) {
      toastr.error("An Error Occured", "Please try again");
      console.log(ex.response.data);
      return ex.response.data.message;
    }
  }
};
