import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import reportReducer from "./report";
import notificationReducer from "./notification";
import authorReducer from "./authors";
import genreReducer from "./genre";
import bookReducer from "./books";
import userReducer from "./user";
import publisherReducer from "./publishers";
import miscReducer from "./misc";
import referralReducer from "./referral";
import rolesReducer from "./roles";
import blogReducer from "./blog";

const reducer = () =>
  combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
    report: reportReducer,
    notification: notificationReducer,
    authors: authorReducer,
    genre: genreReducer,
    book: bookReducer,
    user: userReducer,
    publisher: publisherReducer,
    miscellaneous: miscReducer,
    referral: referralReducer,
    roles: rolesReducer,
    blog: blogReducer,
    toastr: toastrReducer,
  });

export default reducer;
