import {
  FETCH_ALL_BLOGS,
  FETCH_ALL_BLOGS_SUCCESS,
  FETCH_BLOG_BY_ID,
  FETCH_BLOG_BY_ID_SUCCESS,
  CREATE_BLOG,
  CREATE_BLOG_SUCCESS,
  UPDATE_BLOG,
  UPDATE_BLOG_SUCCESS,
  RESTORE_UPDATE_BLOG_INITIAL,
  RESTORE_CREATE_BLOG_INITIAL,
  ADD_BLOG_URL,
  ADD_BLOG_URL_SUCCESS,
  RESTORE_ADD_BLOG_URL_INITIAL,
  DELETE_BLOG,
  DELETE_BLOG_SUCCESS,
  RESTORE_DELETE_BLOG_INITIAL,
} from "../ActionTypes";

export const fetchAllBlogs = () => ({
  type: FETCH_ALL_BLOGS,
});

export const fetchAllBlogsSuccess = (payload) => ({
  type: FETCH_ALL_BLOGS_SUCCESS,
  payload,
});

export const fetchBlogById = (id) => ({
  type: FETCH_BLOG_BY_ID,
  payload: id,
});

export const fetchBlogByIdSuccess = (payload) => ({
  type: FETCH_BLOG_BY_ID_SUCCESS,
  payload,
});

export const addBlog = (payload) => ({
  type: CREATE_BLOG,
  payload,
});

export const addBlogSuccess = (payload) => ({
  type: CREATE_BLOG_SUCCESS,
  payload,
});

export const restoreAddBlogInitial = () => ({
  type: RESTORE_CREATE_BLOG_INITIAL,
});

export const editBlog = ({ blog_title, blog_content, id }) => ({
  type: UPDATE_BLOG,
  payload: {
    blog_title,
    blog_content,
    id,
  },
});

export const editBlogSuccess = (payload) => ({
  type: UPDATE_BLOG_SUCCESS,
  payload,
});

export const restoreEditBlogInitial = () => ({
  type: RESTORE_UPDATE_BLOG_INITIAL,
});

export const addBlogUrl = ({ url, id }) => ({
  type: ADD_BLOG_URL,
  payload: {
    url,
    id,
  },
});

export const addBlogUrlSuccess = (payload) => ({
  type: ADD_BLOG_URL_SUCCESS,
  payload,
});

export const restoreAddBlogUrlInitial = () => ({
  type: RESTORE_ADD_BLOG_URL_INITIAL,
});

export const deleteBlog = (id) => ({
  type: DELETE_BLOG,
  payload: id,
});

export const deleteBlogSuccess = (payload) => ({
  type: DELETE_BLOG_SUCCESS,
  payload,
});

export const restoreDeleteBlogInitial = () => ({
  type: RESTORE_DELETE_BLOG_INITIAL,
});
