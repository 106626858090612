import { all, call, takeEvery, fork } from "redux-saga/effects";
import requestFunction from "../Utils/sagasGenericFunction";

import {
  FETCH_ALL_AUTHORS,
  FETCH_BEST_AUTHORS_CSV,
  ADD_AUTHORS,
  EDIT_AUTHORS,
  DELETE_AUTHORS,
  FETCH_AUTHORS_BY_ID,
  FETCH_ALL_AUTHORS_NO_PAGINATION,
  SEARCH_AUTHOR,
} from "../ActionTypes";

import {
  getAuthors,
  getBestAuthors,
  addAuthorr,
  editAuthorr,
  deleteAuthorr,
  getAuthorsById,
  getAuthorsNoPagination,
  searchAuthor,
} from "../Api";

import {
  fetchAuthorsSuccess,
  fetchBestAuthorsSuccess,
  addAuthorsSuccess,
  editAuthorsSuccess,
  deleteAuthorsSuccess,
  fetchAuthorsByIdSuccess,
  fetchAuthorsNoPaginationSuccess,
  searchAuthorSuccess,
} from "../Actions";

export const fetchAuthorsRequest = function* ({ payload }) {
  yield call(requestFunction, fetchAuthorsSuccess, getAuthors, payload);
};

export const fetchAllAuthhor = function* () {
  yield takeEvery(FETCH_ALL_AUTHORS, fetchAuthorsRequest);
};

export const fetchAuthorsNoPagRequest = function* ({ payload }) {
  yield call(requestFunction, fetchAuthorsNoPaginationSuccess, getAuthorsNoPagination, payload);
};

export const fetchAllAuthorsNoPag = function* () {
  yield takeEvery(FETCH_ALL_AUTHORS_NO_PAGINATION, fetchAuthorsNoPagRequest);
};

export const fetchAuthorsByIdRequest = function* ({ payload }) {
  yield call(requestFunction, fetchAuthorsByIdSuccess, getAuthorsById, payload);
};

export const fetchAuthhorByIdd = function* () {
  yield takeEvery(FETCH_AUTHORS_BY_ID, fetchAuthorsByIdRequest);
};

export const fetchBestAuthorRequest = function* ({ payload }) {
  yield call(requestFunction, fetchBestAuthorsSuccess, getBestAuthors, payload);
};

export const fetchBestAuthorr = function* () {
  yield takeEvery(FETCH_BEST_AUTHORS_CSV, fetchBestAuthorRequest);
};

export const addAuthorRequest = function* ({ payload }) {
  yield call(requestFunction, addAuthorsSuccess, addAuthorr, payload);
};

export const addAuthoors = function* () {
  yield takeEvery(ADD_AUTHORS, addAuthorRequest);
};

export const editAuthorRequest = function* ({ payload }) {
  yield call(requestFunction, editAuthorsSuccess, editAuthorr, payload);
};

export const editAuthoors = function* () {
  yield takeEvery(EDIT_AUTHORS, editAuthorRequest);
};

export const deleteAuthorRequest = function* ({ payload }) {
  yield call(requestFunction, deleteAuthorsSuccess, deleteAuthorr, payload);
};

export const deleteAuthoors = function* () {
  yield takeEvery(DELETE_AUTHORS, deleteAuthorRequest);
};

export const searchAuthorsRequest = function* ({ payload }) {
  yield call(requestFunction, searchAuthorSuccess, searchAuthor, payload);
};

export const searchAuthhor = function* () {
  yield takeEvery(SEARCH_AUTHOR, searchAuthorsRequest);
};

export default function* rootSaga() {
  yield all([
    fork(fetchAllAuthhor),
    fork(fetchBestAuthorr),
    fork(addAuthoors),
    fork(editAuthoors),
    fork(deleteAuthoors),
    fork(fetchAuthhorByIdd),
    fork(fetchAllAuthorsNoPag),
    fork(searchAuthhor),
  ]);
}
