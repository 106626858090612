import api from "../Service";
import { toastr } from "react-redux-toastr";

const baseController = "api/services/";
const baseControllerAdmin = "api/admin/";

export const getCountries = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}countries`);
      if (typeof response !== "undefined") {
        if (response.status === 200) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve countries");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const getSongs = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseControllerAdmin}dashboard/audio/all`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve songs");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const addSongPost = async (body) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.post(`${baseControllerAdmin}dashboard/audio/add`, body, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          toastr.success("Song Added Successfully", "");
          return response.data.status;
        } else {
          toastr.error("Something went wrong", "Please try again");
          return response.data.status;
        }
      } else {
        toastr.error("An Error Occured", "Please try again");
      }
    } catch (ex) {
      toastr.error("An Error Occured", "Please try again");
      // console.log(ex.response.data)
      return ex.response.data.message;
    }
  }
};

export const editSongPost = async (body) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.post(
        `${baseControllerAdmin}dashboard/audio/edit/${body.songId}`,
        body.formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          toastr.success("Song Edited Successfully", "");
          return response.data.status;
        } else {
          toastr.error("Something went wrong", "Please try again");
          return response.data.status;
        }
      } else {
        toastr.error("An Error Occured", "Please try again");
      }
    } catch (ex) {
      toastr.error("An Error Occured", "Please try again");
      // console.log(ex.response.data)
      return ex.response.data.message;
    }
  }
};

export const deleteSongGet = async (id) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseControllerAdmin}dashboard/audio/delete/${id}`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          toastr.success("Song Deleted Successfully", "");
          return response.data.status;
        }
      } else {
        toastr.error("An Error occured", "Could not delete song");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const getSongById = async (id) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseControllerAdmin}dashboard/audio/get/${id}`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve song");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};
