import {
  FETCH_ALL_AUTHORS_SUCCESS,
  FETCH_BEST_AUTHORS_CSV_SUCCESS,
  ADD_AUTHORS_SUCCESS,
  RESTORE_ADD_AUTHORS_INITIAL,
  EDIT_AUTHORS_SUCCESS,
  RESTORE_EDIT_AUTHORS_INITIAL,
  DELETE_AUTHORS_SUCCESS,
  RESTORE_DELETE_AUTHORS_INITIAL,
  FETCH_AUTHORS_BY_ID_SUCCESS,
  FETCH_ALL_AUTHORS_NO_PAGINATION_SUCCESS,
  SEARCH_AUTHOR_SUCCESS,
} from "../ActionTypes";

const INIT_STATE = {
  allAuthors: [],
  allAuthorsNoPagination: [],
  bestAuthors: [],
  authorById: {},

  addAuthorsSuccess: false,
  addAuthorsFailure: false,

  editAuthorsSuccess: false,
  editAuthorsFailure: false,

  deleteAuthorsSuccess: false,
  deleteAuthorsFailure: false,

  searchedAuthor: [],
};

const authorReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_AUTHORS_SUCCESS: {
      return {
        ...state,
        allAuthors: action.payload,
      };
    }

    case FETCH_ALL_AUTHORS_NO_PAGINATION_SUCCESS: {
      return {
        ...state,
        allAuthorsNoPagination: action.payload,
      };
    }

    case FETCH_AUTHORS_BY_ID_SUCCESS: {
      return {
        ...state,
        authorById: action.payload,
      };
    }

    case FETCH_BEST_AUTHORS_CSV_SUCCESS: {
      return {
        ...state,
        bestAuthors: action.payload,
      };
    }

    case ADD_AUTHORS_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          addAuthorsSuccess: true,
          addAuthorsFailure: false,
        };
      } else {
        return {
          ...state,
          addAuthorsSuccess: false,
          addAuthorsFailure: true,
        };
      }
    }

    case RESTORE_ADD_AUTHORS_INITIAL: {
      return {
        ...state,
        addAuthorsSuccess: false,
        addAuthorsFailure: false,
      };
    }

    case EDIT_AUTHORS_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          editAuthorsSuccess: true,
          editAuthorsFailure: false,
        };
      } else {
        return {
          ...state,
          editAuthorsSuccess: false,
          editAuthorsFailure: true,
        };
      }
    }

    case RESTORE_EDIT_AUTHORS_INITIAL: {
      return {
        ...state,
        editAuthorsSuccess: false,
        editAuthorsFailure: false,
      };
    }

    case DELETE_AUTHORS_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          deleteAuthorsSuccess: true,
          deleteAuthorsFailure: false,
        };
      } else {
        return {
          ...state,
          deleteAuthorsSuccess: false,
          deleteAuthorsFailure: true,
        };
      }
    }

    case RESTORE_DELETE_AUTHORS_INITIAL: {
      return {
        ...state,
        deleteAuthorsSuccess: false,
        deleteAuthorsFailure: false,
      };
    }

    case SEARCH_AUTHOR_SUCCESS: {
      return {
        ...state,
        searchedAuthor: action.payload,
      };
    }

    default:
      return state;
  }
};

export default authorReducer;
