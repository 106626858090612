import { useEffect } from "react";

// -----------

const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  // [window.location.pathname]

  return null;
};

export default ScrollToTop;
