import {
  FETCH_ALL_BOOKS_SUCCESS,
  ADD_BOOKS_SUCCESS,
  RESTORE_ADD_BOOKS_INITIAL,
  EDIT_BOOKS_SUCCESS,
  RESTORE_EDIT_BOOKS_INITIAL,
  DELETE_BOOKS_SUCCESS,
  RESTORE_DELETE_BOOKS_INITIAL,
  FETCH_BOOKS_BY_ID_SUCCESS,
  SEARCH_BOOKS_SUCCESS,
} from "../ActionTypes";

const INIT_STATE = {
  allBooks: [],
  bookById: {},
  searchedBooks: [],

  addBookSuccess: false,
  addBookFailure: false,

  editBookSuccess: false,
  editBookFailure: false,

  deleteBookSuccess: false,
  deleteBookFailure: false,
};

const bookReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_BOOKS_SUCCESS: {
      return {
        ...state,
        allBooks: action.payload,
      };
    }

    case SEARCH_BOOKS_SUCCESS: {
      return {
        ...state,
        searchedBooks: action.payload,
      };
    }

    case FETCH_BOOKS_BY_ID_SUCCESS: {
      return {
        ...state,
        bookById: action.payload,
      };
    }

    case ADD_BOOKS_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          addBookSuccess: true,
          addBookFailure: false,
        };
      } else {
        return {
          ...state,
          addBookSuccess: false,
          addBookFailure: true,
        };
      }
    }

    case RESTORE_ADD_BOOKS_INITIAL: {
      return {
        ...state,
        addBookSuccess: false,
        addBookFailure: false,
      };
    }

    case EDIT_BOOKS_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          editBookSuccess: true,
          editBookFailure: false,
        };
      } else {
        return {
          ...state,
          editBookSuccess: false,
          editBookFailure: true,
        };
      }
    }

    case RESTORE_EDIT_BOOKS_INITIAL: {
      return {
        ...state,
        editBookSuccess: false,
        editBookFailure: false,
      };
    }

    case DELETE_BOOKS_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          deleteBookSuccess: true,
          deleteBookFailure: false,
        };
      } else {
        return {
          ...state,
          deleteBookSuccess: false,
          deleteBookFailure: true,
        };
      }
    }

    case RESTORE_DELETE_BOOKS_INITIAL: {
      return {
        ...state,
        deleteBookSuccess: false,
        deleteBookFailure: false,
      };
    }

    default:
      return state;
  }
};

export default bookReducer;
