import api from "../Service";
import { toastr } from "react-redux-toastr";

const baseController = "api/admin/";

export const getReferredUsers = async (id) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(
        `${baseController}dashboard/referral_information/all?page=${id}`
      );
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve referred users");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const getReferralsByUser = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/referral_information/referrals`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve referrals");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};
