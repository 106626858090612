import {
  FETCH_ALL_GENRE_SUCCESS,
  FETCH_SUBGENRE_SUCCESS,
  EMPTY_SUBGENRE,
  ADD_GENRE_SUCCESS,
  RESTORE_ADD_GENRE_INITIAL,
  EDIT_GENRE_SUCCESS,
  RESTORE_EDIT_GENRE_INITIAL,
  DELETE_GENRE_SUCCESS,
  RESTORE_DELETE_GENRE_INITIAL,
  ADD_SUBGENRE_SUCCESS,
  RESTORE_ADD_SUBGENRE_INITIAL,
  EDIT_SUBGENRE_SUCCESS,
  RESTORE_EDIT_SUBGENRE_INITIAL,
  DELETE_SUBGENRE_SUCCESS,
  RESTORE_DELETE_SUBGENRE_INITIAL,
  FETCH_GENRE_BY_ID_SUCCESS,
  FETCH_SUBGENRE_BY_ID_SUCCESS,
} from "../ActionTypes";

const INIT_STATE = {
  allGenre: [],
  subGenre: [],

  addGenreSuccess: false,
  addGenreFailure: false,

  editGenreSuccess: false,
  editGenreFailure: false,

  deleteGenreSuccess: false,
  deleteGenreFailure: false,

  addSubGenreSuccess: false,
  addSubGenreFailure: false,

  editSubGenreSuccess: false,
  editSubGenreFailure: false,

  deleteSubGenreSuccess: false,
  deleteSubGenreFailure: false,

  genreById: {},
  subGenreById: {},
};

const genreReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_GENRE_SUCCESS: {
      return {
        ...state,
        allGenre: action.payload,
      };
    }

    case FETCH_SUBGENRE_SUCCESS: {
      return {
        ...state,
        subGenre: action.payload,
      };
    }

    case EMPTY_SUBGENRE: {
      return {
        ...state,
        subGenre: [],
      };
    }

    case FETCH_GENRE_BY_ID_SUCCESS: {
      return {
        ...state,
        genreById: action.payload,
      };
    }

    case FETCH_SUBGENRE_BY_ID_SUCCESS: {
      return {
        ...state,
        subGenreById: action.payload,
      };
    }

    case ADD_GENRE_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          addGenreSuccess: true,
          addGenreFailure: false,
        };
      } else {
        return {
          ...state,
          addGenreSuccess: false,
          addGenreFailure: true,
        };
      }
    }

    case RESTORE_ADD_GENRE_INITIAL: {
      return {
        ...state,
        addGenreSuccess: false,
        addGenreFailure: false,
      };
    }

    case EDIT_GENRE_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          editGenreSuccess: true,
          editGenreFailure: false,
        };
      } else {
        return {
          ...state,
          editGenreSuccess: false,
          editGenreFailure: true,
        };
      }
    }

    case RESTORE_EDIT_GENRE_INITIAL: {
      return {
        ...state,
        editGenreSuccess: false,
        editGenreFailure: false,
      };
    }

    case DELETE_GENRE_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          deleteGenreSuccess: true,
          deleteGenreFailure: false,
        };
      } else {
        return {
          ...state,
          deleteGenreSuccess: false,
          deleteGenreFailure: true,
        };
      }
    }

    case RESTORE_DELETE_GENRE_INITIAL: {
      return {
        ...state,
        deleteGenreSuccess: false,
        deleteGenreFailure: false,
      };
    }

    case ADD_SUBGENRE_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          addSubGenreSuccess: true,
          addSubGenreFailure: false,
        };
      } else {
        return {
          ...state,
          addSubGenreSuccess: false,
          addSubGenreFailure: true,
        };
      }
    }

    case RESTORE_ADD_SUBGENRE_INITIAL: {
      return {
        ...state,
        addSubGenreSuccess: false,
        addSubGenreFailure: false,
      };
    }

    case EDIT_SUBGENRE_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          editSubGenreSuccess: true,
          editSubGenreFailure: false,
        };
      } else {
        return {
          ...state,
          editSubGenreSuccess: false,
          editSubGenreFailure: true,
        };
      }
    }

    case RESTORE_EDIT_SUBGENRE_INITIAL: {
      return {
        ...state,
        editSubGenreSuccess: false,
        editSubGenreFailure: false,
      };
    }

    case DELETE_SUBGENRE_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          deleteSubGenreSuccess: true,
          deleteSubGenreFailure: false,
        };
      } else {
        return {
          ...state,
          deleteSubGenreSuccess: false,
          deleteSubGenreFailure: true,
        };
      }
    }

    case RESTORE_DELETE_SUBGENRE_INITIAL: {
      return {
        ...state,
        deleteSubGenreSuccess: false,
        deleteSubGenreFailure: false,
      };
    }

    default:
      return state;
  }
};

export default genreReducer;
