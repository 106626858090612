import {
  LOGIN_SUCCESS,
  RESTORE_LOGIN_INITIAL,
  FORGOT_PASSWORD_SUCCESS,
  RESTORE_FORGOT_PASSWORD_INITIAL,
  RESET_PASSWORD_SUCCESS,
  RESTORE_RESET_PASSWORD_INITIAL,
} from "../ActionTypes";

const INIT_STATE = {
  loginSuccess: false,
  loginFailure: false,
  loginData: {},

  forgotPasswordSuccess: false,
  forgotPasswordFailure: false,

  resetPasswordSuccess: false,
  resetPasswordFailure: false,
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      if (action.payload.status === true) {
        return {
          ...state,
          loginSuccess: true,
          loginFailure: false,
          loginData: action.payload,
        };
      } else {
        return {
          ...state,
          loginSuccess: false,
          loginFailure: true,
          loginData: {},
        };
      }
    }

    case RESTORE_LOGIN_INITIAL: {
      return {
        ...state,
        loginSuccess: false,
        loginFailure: false,
        loginData: {},
      };
    }

    case FORGOT_PASSWORD_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          forgotPasswordSuccess: true,
          forgotPasswordFailure: false,
        };
      } else {
        return {
          ...state,
          forgotPasswordSuccess: false,
          forgotPasswordFailure: true,
        };
      }
    }

    case RESTORE_FORGOT_PASSWORD_INITIAL: {
      return {
        ...state,
        forgotPasswordFailure: false,
        forgotPasswordSuccess: false,
      };
    }

    case RESET_PASSWORD_SUCCESS: {
      if (action.payload === true) {
        return {
          ...state,
          resetPasswordSuccess: true,
          resetPasswordFailure: false,
        };
      } else {
        return {
          ...state,
          resetPasswordSuccess: false,
          resetPasswordFailure: true,
        };
      }
    }

    case RESTORE_RESET_PASSWORD_INITIAL: {
      return {
        ...state,
        resetPasswordFailure: false,
        resetPasswordSuccess: false,
      };
    }

    default:
      return state;
  }
};

export default authReducer;
