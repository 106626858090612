import api from "../Service";
import { toastr } from "react-redux-toastr";

const baseController = "api/admin/";
const baseControllerService = "api/services/";

export const getBookReport = async (id) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/report/books?page=${id}`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve book report");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const getUsersReport = async (id) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/report/users?page=${id}`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve users report");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const getSubscriptionReport = async (id) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/report/subscriptions?page=${id}`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve Subscription report");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const downloadBookReportCSV = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/report/books/download`);
      if (typeof response !== "undefined") {
        if (response.status === 200) {
          return response.data;
        }
      } else {
        toastr.error("An Error occured", "Could not download book report");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const downloadSubReportCSV = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/report/subscriptions/download`);
      if (typeof response !== "undefined") {
        if (response.status === 200) {
          return response.data;
        }
      } else {
        toastr.error("An Error occured", "Could not download subscription report");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const downloadAllUsersReportCSV = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/report/users/download`);
      if (typeof response !== "undefined") {
        if (response.status === 200) {
          return response.data;
        }
      } else {
        toastr.error("An Error occured", "Could not download users report");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const downloadTrialUsersReportCSV = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/report/users/trial/download`);
      if (typeof response !== "undefined") {
        if (response.status === 200) {
          return response.data;
        }
      } else {
        toastr.error("An Error occured", "Could not download users report");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const downloadExpiredUsersReportCSV = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/report/users/expired/download`);
      if (typeof response !== "undefined") {
        if (response.status === 200) {
          return response.data;
        }
      } else {
        toastr.error("An Error occured", "Could not download users report");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const downloadNeverSubscribedUsersReportCSV = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(
        `${baseController}dashboard/report/users/nonsubscribed/download`
      );
      if (typeof response !== "undefined") {
        if (response.status === 200) {
          return response.data;
        }
      } else {
        toastr.error("An Error occured", "Could not download users report");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const downloadLoneRangerUsersReportCSV = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/report/users/loneranger/download`);
      if (typeof response !== "undefined") {
        if (response.status === 200) {
          return response.data;
        }
      } else {
        toastr.error("An Error occured", "Could not download users report");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const downloadUnverifiedUsersReportCSV = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/report/users/unverified/download`);
      if (typeof response !== "undefined") {
        if (response.status === 200) {
          return response.data;
        }
      } else {
        toastr.error("An Error occured", "Could not download users report");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const getTransactionReport = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/report/transactions`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve dashboard details");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const getTransactionReportCSV = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/report/transactions/download`);
      if (typeof response !== "undefined") {
        if (response.status === 200) {
          return response.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve dashboard details");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const filterUserbyPlanOrStatus = async (body) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.post(`${baseController}dashboard/report/users/filter`, body);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not filter user");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const getAllSubscription = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseControllerService}subscriptions`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve subscriptions");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};
