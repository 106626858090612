import api from "../Service";
import { toastr } from "react-redux-toastr";

const baseController = "api/admin/";

export const getDashboardDetails = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard`);
      if (typeof response !== "undefined") {
        if (response.status === 200) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve dashboard details");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const getDashboardBookDetails = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/book/details`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve books details");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const getDashboardUserDetails = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/user/details`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve users details");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

//this endpoint has been removed from the documentation for some unknown
export const getDashboardWithdrawalDetails = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/withdrawal/details`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve dashboard details");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const getWithdrawalRequest = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/withdrawal-requests`);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve withdrawal request");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const addMaxLimit = async (body) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.post(`${baseController}dashboard/updatemax`, body);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          toastr.success("Maximum withdrawal Limit was set Successfully", "");
          return response.data.status;
        } else {
          toastr.error("Couldnt set maximum withdrawal limit", "Please try again");
          return response.data.status;
        }
      } else {
        toastr.error("An Error Occured", "Please try again");
      }
    } catch (ex) {
      toastr.error("An Error Occured", "Please try again");
      //   return ex.response.data.message
    }
  }
};

export const addMinLimit = async (body) => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.post(`${baseController}dashboard/updatemin`, body);
      if (typeof response !== "undefined") {
        if (response.status === 200 && response.data.status === true) {
          toastr.success("Minimum withdrawal Limit was set Successfully", "");
          return response.data.status;
        } else {
          toastr.error("Couldnt set minimum withdrawal limit", "Please try again");
          return response.data.status;
        }
      } else {
        toastr.error("An Error Occured", "Please try again");
      }
    } catch (ex) {
      toastr.error("An Error Occured", "Please try again");
      //   return ex.response.data.message
    }
  }
};

export const getDashboardAndriodDownload = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/app/android/details`);
      if (typeof response !== "undefined") {
        if (response.status === 200) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve android app report");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};

export const getDashboardIOSDownload = async () => {
  if (navigator.onLine === false) {
    toastr.error("No Internet Connection", "Please try again");
  } else {
    try {
      const response = await api.get(`${baseController}dashboard/app/ios/details`);
      if (typeof response !== "undefined") {
        if (response.status === 200) {
          return response.data.data;
        }
      } else {
        toastr.error("An Error occured", "Could not retrieve IOS app report");
      }
    } catch (ex) {
      toastr.error("An Error occurred", "Please try again");
    }
  }
};
