import {
  FETCH_DASHBOARD_DETAILS,
  FETCH_DASHBOARD_DETAILS_SUCCESS,
  FETCH_WITHDRAWAL_REQUEST,
  FETCH_WITHDRAWAL_REQUEST_SUCCESS,
  ADD_MAX_WITHDRAWAL_LIMIT,
  ADD_MAX_WITHDRAWAL_LIMIT_SUCCESS,
  ADD_MIN_WITHDRAWAL_LIMIT,
  ADD_MIN_WITHDRAWAL_LIMIT_SUCCESS,
  RESTORE_ADD_MAX_WITHDRAWAL_LIMIT_INITIAL,
  RESTORE_ADD_MIN_WITHDRAWAL_LIMIT_INITIAL,
  FETCH_DASHBOARD_BOOK_DETAILS,
  FETCH_DASHBOARD_BOOK_DETAILS_SUCCESS,
  FETCH_DASHBOARD_USER_DETAILS,
  FETCH_DASHBOARD_USER_DETAILS_SUCCESS,
  FETCH_DASHBOARD_WITHDRAWAL_DETAILS,
  FETCH_DASHBOARD_WITHDRAWAL_DETAILS_SUCCESS,
  FETCH_DASHBOARD_ANDRIOD_DOWNLOAD,
  FETCH_DASHBOARD_ANDRIOD_DOWNLOAD_SUCCESS,
  FETCH_DASHBOARD_IOS_DOWNLOAD,
  FETCH_DASHBOARD_IOS_DOWNLOAD_SUCCESS,
} from "../ActionTypes";

export const fetchDashboardDetails = () => ({
  type: FETCH_DASHBOARD_DETAILS,
});

export const fetchDashboardDetailsSuccess = (payload) => ({
  type: FETCH_DASHBOARD_DETAILS_SUCCESS,
  payload,
});

export const fetchDashboardBookDetails = () => ({
  type: FETCH_DASHBOARD_BOOK_DETAILS,
});

export const fetchDashboardBookDetailsSuccess = (payload) => ({
  type: FETCH_DASHBOARD_BOOK_DETAILS_SUCCESS,
  payload,
});

export const fetchDashboardUserDetails = () => ({
  type: FETCH_DASHBOARD_USER_DETAILS,
});

export const fetchDashboardUserDetailsSuccess = (payload) => ({
  type: FETCH_DASHBOARD_USER_DETAILS_SUCCESS,
  payload,
});

export const fetchDashboardWithdrawalDetails = () => ({
  type: FETCH_DASHBOARD_WITHDRAWAL_DETAILS,
});

export const fetchDashboardWithdrawalDetailsSuccess = (payload) => ({
  type: FETCH_DASHBOARD_WITHDRAWAL_DETAILS_SUCCESS,
  payload,
});

export const fetchWithdrawalRequest = () => ({
  type: FETCH_WITHDRAWAL_REQUEST,
});

export const fetchWithdrawalRequestSuccess = (payload) => ({
  type: FETCH_WITHDRAWAL_REQUEST_SUCCESS,
  payload,
});

export const addMaxWithdrawalLimit = ({ amount }) => ({
  type: ADD_MAX_WITHDRAWAL_LIMIT,
  payload: {
    amount,
  },
});

export const addMaxWithdrawalLimitSuccess = (payload) => ({
  type: ADD_MAX_WITHDRAWAL_LIMIT_SUCCESS,
  payload,
});

export const restoreAddMaxWithdrawalLimitInitial = () => ({
  type: RESTORE_ADD_MAX_WITHDRAWAL_LIMIT_INITIAL,
});

export const addMinWithdrawalLimit = ({ amount }) => ({
  type: ADD_MIN_WITHDRAWAL_LIMIT,
  payload: {
    amount,
  },
});

export const addMinWithdrawalLimitSuccess = (payload) => ({
  type: ADD_MIN_WITHDRAWAL_LIMIT_SUCCESS,
  payload,
});

export const restoreAddMinWithdrawalLimitInitial = () => ({
  type: RESTORE_ADD_MIN_WITHDRAWAL_LIMIT_INITIAL,
});

export const fetchDashboardAndriodDownload = () => ({
  type: FETCH_DASHBOARD_ANDRIOD_DOWNLOAD,
});

export const fetchDashboardAndriodDownloadSuccess = (payload) => ({
  type: FETCH_DASHBOARD_ANDRIOD_DOWNLOAD_SUCCESS,
  payload,
});

export const fetchDashboardIOSDownload = () => ({
  type: FETCH_DASHBOARD_IOS_DOWNLOAD,
});

export const fetchDashboardIOSDownloadSuccess = (payload) => ({
  type: FETCH_DASHBOARD_IOS_DOWNLOAD_SUCCESS,
  payload,
});
