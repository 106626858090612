import { all, call, takeEvery, fork } from "redux-saga/effects";
import requestFunction from "../Utils/sagasGenericFunction";

import {
  FETCH_DASHBOARD_DETAILS,
  FETCH_WITHDRAWAL_REQUEST,
  ADD_MAX_WITHDRAWAL_LIMIT,
  ADD_MIN_WITHDRAWAL_LIMIT,
  FETCH_DASHBOARD_BOOK_DETAILS,
  FETCH_DASHBOARD_USER_DETAILS,
  FETCH_DASHBOARD_WITHDRAWAL_DETAILS,
  FETCH_DASHBOARD_ANDRIOD_DOWNLOAD,
  FETCH_DASHBOARD_IOS_DOWNLOAD,
} from "../ActionTypes";

import {
  getDashboardDetails,
  getWithdrawalRequest,
  addMaxLimit,
  addMinLimit,
  getDashboardBookDetails,
  getDashboardUserDetails,
  getDashboardWithdrawalDetails,
  getDashboardAndriodDownload,
  getDashboardIOSDownload,
} from "../Api";

import {
  fetchDashboardDetailsSuccess,
  fetchWithdrawalRequestSuccess,
  addMaxWithdrawalLimitSuccess,
  addMinWithdrawalLimitSuccess,
  fetchDashboardBookDetailsSuccess,
  fetchDashboardUserDetailsSuccess,
  fetchDashboardWithdrawalDetailsSuccess,
  fetchDashboardAndriodDownloadSuccess,
  fetchDashboardIOSDownloadSuccess,
} from "../Actions";

export const fetchDashboardRequest = function* ({ payload }) {
  yield call(requestFunction, fetchDashboardDetailsSuccess, getDashboardDetails, payload);
};

export const fetchDashb = function* () {
  yield takeEvery(FETCH_DASHBOARD_DETAILS, fetchDashboardRequest);
};

export const fetchDashboardBookRequest = function* ({ payload }) {
  yield call(requestFunction, fetchDashboardBookDetailsSuccess, getDashboardBookDetails, payload);
};

export const fetchDashbBkDetails = function* () {
  yield takeEvery(FETCH_DASHBOARD_BOOK_DETAILS, fetchDashboardBookRequest);
};

export const fetchDashboardUserRequest = function* ({ payload }) {
  yield call(requestFunction, fetchDashboardUserDetailsSuccess, getDashboardUserDetails, payload);
};

export const fetchDashbUsrDtals = function* () {
  yield takeEvery(FETCH_DASHBOARD_USER_DETAILS, fetchDashboardUserRequest);
};

export const fetchDashboardWithdrawalRequest = function* ({ payload }) {
  yield call(
    requestFunction,
    fetchDashboardWithdrawalDetailsSuccess,
    getDashboardWithdrawalDetails,
    payload
  );
};

export const fetchDashbWithdrwalDtals = function* () {
  yield takeEvery(FETCH_DASHBOARD_WITHDRAWAL_DETAILS, fetchDashboardWithdrawalRequest);
};

export const fetchWithdrawalRequest = function* ({ payload }) {
  yield call(requestFunction, fetchWithdrawalRequestSuccess, getWithdrawalRequest, payload);
};

export const fetchWithdrawal = function* () {
  yield takeEvery(FETCH_WITHDRAWAL_REQUEST, fetchWithdrawalRequest);
};

export const addMaxWitdrawalLimitRequest = function* ({ payload }) {
  yield call(requestFunction, addMaxWithdrawalLimitSuccess, addMaxLimit, payload);
};

export const addMaxxLimitt = function* () {
  yield takeEvery(ADD_MAX_WITHDRAWAL_LIMIT, addMaxWitdrawalLimitRequest);
};

export const addMinWitdrawalLimitRequest = function* ({ payload }) {
  yield call(requestFunction, addMinWithdrawalLimitSuccess, addMinLimit, payload);
};

export const addMinnLimitt = function* () {
  yield takeEvery(ADD_MIN_WITHDRAWAL_LIMIT, addMinWitdrawalLimitRequest);
};

export const fetchDashboardAndroidRequest = function* ({ payload }) {
  yield call(
    requestFunction,
    fetchDashboardAndriodDownloadSuccess,
    getDashboardAndriodDownload,
    payload
  );
};

export const fetchDashbAndriod = function* () {
  yield takeEvery(FETCH_DASHBOARD_ANDRIOD_DOWNLOAD, fetchDashboardAndroidRequest);
};

export const fetchDashboardIOSRequest = function* ({ payload }) {
  yield call(requestFunction, fetchDashboardIOSDownloadSuccess, getDashboardIOSDownload, payload);
};

export const fetchDashbIOS = function* () {
  yield takeEvery(FETCH_DASHBOARD_IOS_DOWNLOAD, fetchDashboardIOSRequest);
};

export default function* rootSaga() {
  yield all([
    fork(fetchDashb),
    fork(fetchWithdrawal),
    fork(addMaxxLimitt),
    fork(addMinnLimitt),
    fork(fetchDashbBkDetails),
    fork(fetchDashbUsrDtals),
    fork(fetchDashbWithdrwalDtals),
    fork(fetchDashbAndriod),
    fork(fetchDashbIOS),
  ]);
}
