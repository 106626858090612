import { all, call, takeEvery, fork } from "redux-saga/effects";
import requestFunction from "../Utils/sagasGenericFunction";

import {
  ADD_GENRE,
  EDIT_GENRE,
  DELETE_GENRE,
  ADD_SUBGENRE,
  EDIT_SUBGENRE,
  DELETE_SUBGENRE,
  FETCH_ALL_GENRE,
  FETCH_SUBGENRE,
  FETCH_GENRE_BY_ID,
  FETCH_SUBGENRE_BY_ID,
} from "../ActionTypes";

import {
  getGenres,
  getSubGenre,
  addGenre,
  editGenre,
  deleteGenre,
  addSubGenre,
  editSubGenre,
  deleteSubGenre,
  getSubGenreById,
  getGenreById,
} from "../Api";

import {
  fetchAllGenreSuccess,
  fetchSubGenreSuccess,
  fetchGenreByIdSuccess,
  fetchSubGenreByIdSuccess,
  addGenreSuccess,
  editGenreSuccess,
  deleteGenreSuccess,
  addSubGenreSuccess,
  editSubGenreSuccess,
  deleteSubGenreSuccess,
} from "../Actions";

export const fetchGenreRequest = function* ({ payload }) {
  yield call(requestFunction, fetchAllGenreSuccess, getGenres, payload);
};

export const fetchGenr = function* () {
  yield takeEvery(FETCH_ALL_GENRE, fetchGenreRequest);
};

export const fetchSubGenreRequest = function* ({ payload }) {
  yield call(requestFunction, fetchSubGenreSuccess, getSubGenre, payload);
};

export const fetchSubGen = function* () {
  yield takeEvery(FETCH_SUBGENRE, fetchSubGenreRequest);
};

export const fetchGenreByIdRequest = function* ({ payload }) {
  yield call(requestFunction, fetchGenreByIdSuccess, getGenreById, payload);
};

export const fetchGenById = function* () {
  yield takeEvery(FETCH_GENRE_BY_ID, fetchGenreByIdRequest);
};

export const fetchSubGenreByIdRequest = function* ({ payload }) {
  yield call(requestFunction, fetchSubGenreByIdSuccess, getSubGenreById, payload);
};

export const fetchSubGenById = function* () {
  yield takeEvery(FETCH_SUBGENRE_BY_ID, fetchSubGenreByIdRequest);
};

export const addGenreRequest = function* ({ payload }) {
  yield call(requestFunction, addGenreSuccess, addGenre, payload);
};

export const addGen = function* () {
  yield takeEvery(ADD_GENRE, addGenreRequest);
};

export const editGenreRequest = function* ({ payload }) {
  yield call(requestFunction, editGenreSuccess, editGenre, payload);
};

export const editGenr = function* () {
  yield takeEvery(EDIT_GENRE, editGenreRequest);
};

export const deleteGenreRequest = function* ({ payload }) {
  yield call(requestFunction, deleteGenreSuccess, deleteGenre, payload);
};

export const deleteGenr = function* () {
  yield takeEvery(DELETE_GENRE, deleteGenreRequest);
};

export const addSubGenreRequest = function* ({ payload }) {
  yield call(requestFunction, addSubGenreSuccess, addSubGenre, payload);
};

export const addSubGenr = function* () {
  yield takeEvery(ADD_SUBGENRE, addSubGenreRequest);
};

export const editSubGenreRequest = function* ({ payload }) {
  yield call(requestFunction, editSubGenreSuccess, editSubGenre, payload);
};

export const editSubGenr = function* () {
  yield takeEvery(EDIT_SUBGENRE, editSubGenreRequest);
};

export const deleteSubGenreRequest = function* ({ payload }) {
  yield call(requestFunction, deleteSubGenreSuccess, deleteSubGenre, payload);
};

export const deleteSubGenr = function* () {
  yield takeEvery(DELETE_SUBGENRE, deleteSubGenreRequest);
};

export default function* rootSaga() {
  yield all([
    fork(fetchGenr),
    fork(fetchSubGen),
    fork(addGen),
    fork(editGenr),
    fork(editSubGenr),
    fork(fetchGenById),
    fork(fetchSubGenById),
    fork(deleteGenr),
    fork(addSubGenr),
    fork(deleteSubGenr),
  ]);
}
