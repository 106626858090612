import { all, call, takeEvery, fork } from "redux-saga/effects";
import requestFunction from "../Utils/sagasGenericFunction";

import {
  FETCH_ALL_COUNTRIES,
  CREATE_SONG,
  EDIT_SONG,
  DELETE_SONG,
  FETCH_SONGS,
  FETCH_SONG_BY_ID,
} from "../ActionTypes";

import {
  getCountries,
  addSongPost,
  editSongPost,
  deleteSongGet,
  getSongs,
  getSongById,
} from "../Api";

import {
  fetchCountriesSuccess,
  fetchSongsSuccess,
  addSongSuccess,
  editSongSuccess,
  deleteSongSuccess,
  fetchSongByIdSuccess,
} from "../Actions";

export const fetchCountryRequest = function* ({ payload }) {
  yield call(requestFunction, fetchCountriesSuccess, getCountries, payload);
};

export const fetchAllCounty = function* () {
  yield takeEvery(FETCH_ALL_COUNTRIES, fetchCountryRequest);
};

export const fetchSongRequest = function* ({ payload }) {
  yield call(requestFunction, fetchSongsSuccess, getSongs, payload);
};

export const fetchAllSongss = function* () {
  yield takeEvery(FETCH_SONGS, fetchSongRequest);
};

export const addSongRequest = function* ({ payload }) {
  yield call(requestFunction, addSongSuccess, addSongPost, payload);
};

export const addSongss = function* () {
  yield takeEvery(CREATE_SONG, addSongRequest);
};

export const editSongRequest = function* ({ payload }) {
  yield call(requestFunction, editSongSuccess, editSongPost, payload);
};

export const editSongss = function* () {
  yield takeEvery(EDIT_SONG, editSongRequest);
};

export const deleteSongRequest = function* ({ payload }) {
  yield call(requestFunction, deleteSongSuccess, deleteSongGet, payload);
};

export const deleteSongss = function* () {
  yield takeEvery(DELETE_SONG, deleteSongRequest);
};

export const fetchSongByIdRequest = function* ({ payload }) {
  yield call(requestFunction, fetchSongByIdSuccess, getSongById, payload);
};

export const fetchSongByIddx = function* () {
  yield takeEvery(FETCH_SONG_BY_ID, fetchSongByIdRequest);
};

export default function* rootSaga() {
  yield all([
    fork(fetchAllCounty),
    fork(fetchAllSongss),
    fork(addSongss),
    fork(editSongss),
    fork(deleteSongss),
    fork(fetchSongByIddx),
  ]);
}
